<template>
    <div>
        <v-dialog
            v-model="showDialogPermitStages"
            transition="dialog-top-transition"
            persistent
            width="900"
            :fullscreen="$vuetify.breakpoint.mobile"
        >
            <v-card>
                <v-card-title class="lighten-2 formDialog" style="border-bottom: solid 1px var(--color__cinza); font-size: 17px;">
                    {{ permitTitle }}
                </v-card-title>

                <v-card-text style="margin-top: 20px;">
                    <v-stepper 
                        alt-labels
                        v-model="e1"
                    >
                        <v-stepper-header>
                            <template v-for="(item, index) in listPermitStages">
                                <v-stepper-step
                                    :key="`${index}-step`"
                                    :complete="e1 > index"
                                    :step="index + 1"
                                    editable
                                >
                                    {{ item.permitStageDescription }}
                                </v-stepper-step>

                                <v-divider
                                    v-if="index + 1 !== steps"
                                    :key="index"
                                ></v-divider>
                            </template>
                        </v-stepper-header>

                        <v-stepper-items>
                            <v-stepper-content
                                v-for="(item, index) in listPermitStages"
                                :key="`${index}-content`"
                                :step="index + 1"
                            >
                                <v-row>
                                    <v-col 
                                        cols="12"
                                        lg="12"
                                        md="12"
                                        sm="12"
                                    >
                                        <label>Status</label>
                                        <v-combobox
                                            v-model="fields[`permitStageStatusSelected${index}`]"
                                            :items="listStatus"
                                            item-text="permitStageStatusDescription"
                                            item-value="id"
                                            :rules=[validations.required]
                                            clearable
                                            single-line
                                            outlined
                                            dense
                                        ></v-combobox>
                                    </v-col>

                                    <v-col 
                                        cols="12"
                                        lg="12"
                                        md="12"
                                        sm="12"
                                    >
                                        <label>Note</label>
                                        <v-textarea
                                            v-model="fields[`note${index}`]"
                                            single-line
                                            rows="3"
                                            row-height="30"
                                            outlined
                                        >
                                        </v-textarea>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col 
                                        cols="12"
                                        lg="12"
                                        md="12"
                                        sm="12"
                                    >
                                        <v-card-actions style="padding: 15px !important;">
                                            <div style="width: 100%; text-align: center;">
                                                <v-btn
                                                    v-if="e1 > 1"
                                                    color="mx-2 dialogButtonDismiss"
                                                    @click="previousStep(index)"
                                                >
                                                    Back
                                                </v-btn>

                                                <v-btn
                                                    v-if="index < steps - 1"
                                                    color="mx-2 primary"
                                                    :disabled="fields[`permitStageStatusSelected${index}`] == null || fields[`permitStageStatusSelected${index}`] == undefined"
                                                    @click="nextStep(index)"
                                                >
                                                    Next
                                                </v-btn>
                                            </div>
                                        </v-card-actions>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                        </v-stepper-items>                        
                    </v-stepper>
                </v-card-text>

				<v-divider></v-divider>

				<v-card-actions style="padding: 15px !important;">
					<div style="width: 100%; text-align: center;">
						<v-btn
							class="mx-2 dialogButtonCancel v-btn-large"
							outlined
							@click="cancel"
						>
							Cancel
						</v-btn>

						<v-btn
							class="mx-2 v-btn-large"
							outlined
                            @click="save"
						>
							Save
						</v-btn>
					</div>
				</v-card-actions>

            </v-card>
        </v-dialog> 
	</div>
</template>

<script>

    import Helpers from '@/utilities/Helpers';
    import { required } from "@/utilities/Rules";

	export default {

        mixins: [Helpers],

        props: {
			
            idPermitting: {
                type: Number,
                default: 0
            },

            itemPermitting: {
                type: Object,
                default: null
            },

            permitTitle: {
                type: String,
                default: ''
            },

			showDialogPermitStages: {
				default: false
			},
		},
		
        data: () => ({

            steps: 0,
            e1: 0,
			
            listStatus: [],
            listPermitStages: [],

            fields: {
                permitStageStatusSelected1: null,
                permitStageStatusSelected2: null,
                permitStageStatusSelected3: null,
                permitStageStatusSelected4: null,
                permitStageStatusSelected5: null,
                permitStageStatusSelected6: null,

                note1: null,
                note2: null,
                note3: null,
                note4: null,
                note5: null,
                note6: null,
            },

            validations: {
                required: required
            },
		}),

        watch: {
            async "idPermitting"() {
                await this.getRegisters();
            },

            watch: {
                steps (val) {
                    if (this.e1 > val) {
                        this.e1 = val
                    }
                },
            },
        },

        methods: {
            previousStep (index) {
                if (index === this.steps) {
                    this.e1 = 1
                } else {
                    this.e1 -= 1;
                }
            },

            nextStep (index) {
                if (index === this.steps) {
                    this.e1 = 1
                } else {
                    this.e1 += 1;
                }
            },

            cancel() {
                this.$emit('update:showDialogPermitStages', false);
            },

            async clearFields() {

                this.e1 = 1;
                this.listPermitStages.forEach((_, index) => {                
                    this.fields[`permitStageStatusSelected${index}`] = null;
                    this.fields[`note${index}`] = null;
                });
            },

            async getRegisters() {

                await this.clearFields();

                this.listPermitStages.forEach((itemStage, index) => {
                    const itemPermittingStaging = this.itemPermitting.listPermittingStaging.filter(item => item.permitStageSelected.id == itemStage.id)[0];

                    if (itemPermittingStaging != null && itemPermittingStaging != undefined) {
                        this.fields[`permitStageStatusSelected${index}`] = itemPermittingStaging.permitStageStatusSelected;
                        this.fields[`note${index}`] = itemPermittingStaging.notes;
                        this.e1 = index + 1;
                    }
                    
                    this.$forceUpdate();
                });
            },

            async save() {

                let listPermitingStages = [];

                this.listPermitStages.forEach((itemStage, index) => {

                    const permitStage = itemStage.id;
                    const statusSelected = this.fields[`permitStageStatusSelected${index}`];
                    const notes = this.fields[`note${index}`];

                    let id = 0;
                    const itemPermittingStaging = this.itemPermitting.listPermittingStaging.filter(item => item.permitStageSelected.id == permitStage)[0];
                    if (itemPermittingStaging != null && itemPermittingStaging != undefined) {
                        id = itemPermittingStaging.id;
                    }

                    if (statusSelected != null && statusSelected != undefined) {

                        const permitStageStatus = statusSelected.id;

                        listPermitingStages.push({
                            id,
                            permitStage,
                            permitStageStatus,
                            notes
                        });
                    }
                });

                let listPermittingRequest = [{...this.itemPermitting}];
                listPermittingRequest[0].listPermittingStaging = listPermitingStages;
                
                if (listPermittingRequest[0].expirationDate == "") {
                    listPermittingRequest[0].expirationDate = null;
                }
                
                const result = await this.$store.dispatch("serviceModule/CreateUpdatePermitting", listPermittingRequest);

                if (result.success === true) {
                    this.showToast("success", "Success!", result.message);
                    this.$emit('methodConfirmToCall');
                    this.cancel();
                }
                else {
                    this.showToast("error", "Warning!", result.message);
                }
            }
        },

        async created() {
            this.listPermitStages = await this.$store.dispatch("serviceModule/ListPermitStages");
            this.listStatus = await this.$store.dispatch("serviceModule/ListPermitStagesStatus");
            this.steps = this.listPermitStages.length;
        }
    };
</script>